import React, { useRef, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import FluidAnimation from "react-fluid-animation";

const fluidConfig = {
  textureDownsample: 1,
  densityDissipation: 0.98,
  velocityDissipation: 0.99,
  pressureDissipation: 0.8,
  pressureIterations: 25,
  curl: 30,
  splatRadius: 0.005,
};

function simulateMouseDown(element) {
  element.dispatchEvent(
    new MouseEvent("mousedown", {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1,
    })
  );
}

function WrapperFluid() {
  const wrapperRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    wrapperRef.current.addEventListener(
      "mouseup",
      function (event) {
        event.stopImmediatePropagation();
      },
      true
    );

    wrapperRef.current.addEventListener("mouseenter", function () {
      // var element = document.querySelector(".react-fluid-animation-canvas");
      var element = document.getElementsByTagName("canvas");
      simulateMouseDown(element[0]);
    });
  }, []);

  return (
    <div style={{ height: "100vh" }} ref={wrapperRef}>
      <FluidAnimation config={fluidConfig} ref={animationRef} />
    </div>
  );
}

function App() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [mouseMoved, setMouseMoved] = useState(false);

  useEffect(() => {
    const moveHandler = () => {
      setMouseMoved(true);
      setShowPrompt(false);
      clearTimeout(timer);
      window.removeEventListener("mousemove", moveHandler);
      window.addEventListener("touchstart", moveHandler);
    };

    const timer = setTimeout(() => {
      if (!mouseMoved) {
        setShowPrompt(true);
      }
    }, 1000);

    window.addEventListener("mousemove", moveHandler);
    window.addEventListener("touchstart", moveHandler);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", moveHandler);
      window.addEventListener("touchstart", moveHandler);
    };
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <BlackContainer>
        <WrapperFluid />
        <TextContainer>
          <p>Introducing</p>
          <h1>Alljoined</h1>
          <FadeContainer show={showPrompt}>
            <p>Swipe ---{">"}</p>
          </FadeContainer>
        </TextContainer>
      </BlackContainer>
    </Container>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  body { 
    font-family: 'Inter', sans-serif;
  }
  h1 {
    font-size: 15vw;
    font-weight: 900;
    margin: 0;
  }
  p {
    font-weight: 900;
    margin: 0;
    color: #888;
  }
  `;

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const BlackContainer = styled.div`
  background-color: black;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  user-select: none;
`;

const FadeContainer = styled.div`
  position: relative;
  z-index: 1;
  top: -10vw;
  pointer-events: none;
  user-select: none;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;
